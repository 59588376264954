import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { links } from 'src/app/shared/globalVariables';

@Component({
  selector: 'app-media-links',
  templateUrl: './media-links.component.html',
  styleUrls: ['./media-links.component.scss']
})
export class MediaLinksComponent implements OnDestroy {

  private isSubClickOutside = false;

  public mediaLinksArray = [
    { index: 0, class: 'twarzksiazka', show: false, text: 'Facebook',
      url: links.facebook,
      icon: { file: 'facebook-letter.svg', width: '20px', height: '38px'}
    },
    { index: 1, class: 'judube', show: false, text: 'Allegro',
      url: links.allegro,
      icon: { file: 'allegro-ikonka.svg', width: '39px', height: '39px'}
    },
  ];

  constructor(private eRef: ElementRef) { }

  private $subClickDocument: Subscription = new Subscription();

  private subClickOutside(): Subscription {
    this.isSubClickOutside = true;
    return fromEvent(document, 'click').subscribe(event => {
      if (!this.eRef.nativeElement.contains(event.target)) {
        this.resetShow();
        this.isSubClickOutside = false;
        this.$subClickDocument.unsubscribe();
      }
    });
  }

  public onToggleShow(indexCame: number) {
    const booleanTmp = this.mediaLinksArray[indexCame].show;
    this.resetShow();
    this.mediaLinksArray[indexCame].show = !booleanTmp;
    if (!this.mediaLinksArray.reduce((acc, cur) => acc || cur.show, false)) {
      this.$subClickDocument.unsubscribe();
      this.isSubClickOutside = false;
    } else if (!this.isSubClickOutside) {
      this.$subClickDocument = this.subClickOutside();
    }
  }

  private resetShow() {
    this.mediaLinksArray = this.mediaLinksArray.map( item => {
      item.show = false;
      return item;
    });
  }

  ngOnDestroy() {
    this.$subClickDocument.unsubscribe();
  }

}
