<section class="section__main main-text-content" fxLayout="column" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
  <div fxLayout="column" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
    <h1>Komis gastronomiczny</h1>
    <h2>W kilku słowach o naszej firmie</h2>
  </div>
  <div class="line"></div>
  <p>&emsp;Nasza firma działa na rynku od wielu lat. Przez ten długi czas zdobyliśmy niezbędne doświadczenie, które pozwala nam oferować usługi na najwyższym poziomie.</p>
  <p>&emsp;Zajmujemy się szeroko pojętym skupem oraz sprzedażą wyposażenia gastronomicznego i nie tylko. Nasz wachlarz usług obejmuje również likwidacje lokali wszelkiego typu.</p>
  <p>&emsp;Działamy na terenie całego kraju, 24/h na dobę. Wyceniamy wyposażenie, samodzielnie demontujemy i wywozimy pozostawiając uporządkowany lokal. Oczywiście każda usługa likwidacji przebiega na podstawie ustalonych wcześniej warunków. O nic nie muszą się Państwo martwić.</p>
  <p>Zapraszamy do skorzystania z naszej oferty!</p>
</section>