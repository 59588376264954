<section class="section__width100 landing" fxLayout="row" fxLayoutAlign="center center">
  <picture fxLayout="row" fxLayoutAlign="center center">
    <source srcset="/assets/images/main-1-m.webp" media="(max-width: 599px)">
    <source srcset="/assets/images/main-1.webp" media="(min-width: 600px)">
    <img
        src="/assets/images/main-1-m.webp"
        height="380"
        width="497"
        alt="komis-gastronomia-obrazek-1">
  </picture>
</section>

<section class="section__width100 tiles-container-col-2" fxLayout="row wrap" fxLayoutAlign="space-between center" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start center" fxLayoutGap="0" fxLayoutGap.lt-md="15px">
  <div class="container-imgs" fxLayout="column" fxLayoutAlign="center center">
    <a routerLink="oferta" class="container-imgs-1" fxLayout="row" fxLayoutAlign="center center">
      <picture fxLayout="row" fxLayoutAlign="center center">
        <source srcset="/assets/images/welcome/oferta-1-400.webp" media="(max-width: 599px)">
        <source srcset="/assets/images/welcome/oferta-1-800-m.webp" media="(min-width: 600px) and (max-width: 959px)">
        <source srcset="/assets/images/welcome/oferta-1-800.webp" media="(min-width: 960px)">
        <img
            src="/assets/images/welcome/oferta-1-400.webp"
            height="195"
            width="400"
            alt="oferta-komis-gastronomia">
      </picture>
      <div class="text-box" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px">
        <div class="line"></div>
        <h3>Sprawdź naszą ofertę</h3>
        <p>Wyposażamy gastronomię w maszyny nowe i używane. Naprawiamy, konserwujemy oraz produkujemy maszyny i meble technologiczne dla gastronomii.</p>
      </div>
    </a>
  </div>
  <div class="container-imgs tiles-row-2" fxLayout="column" fxLayoutAlign="space-between center" fxLayoutGap.lt-md="10px">
    <a routerLink="skup-sprzetu-wyposazenia" class="container-imgs-1 container-imgs-1__2-rows" fxLayout="row" fxLayoutAlign="center center">
      <picture fxLayout="row" fxLayoutAlign="center center">
        <source srcset="/assets/images/welcome/oferta-2-400.webp" media="(max-width: 599px)">
        <source srcset="/assets/images/welcome/oferta-2-800.webp" media="(min-width: 600px)">
        <img
            src="/assets/images/welcome/oferta-2-400.webp"
            height="195"
            width="400"
            alt="oferta-komis-gastronomia">
      </picture>
      <div class="text-box" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="3px">
        <div class="line"></div>
        <h3>Skup</h3>
        <p>Odkupimy w rozsądnej cenie sprzęt gastronomiczny i inny, w tym biurowy</p>
      </div>
    </a>
    <a [href]="links.allegro" target="_blank" rel="noopener" class="container-imgs-1 container-imgs-1__2-rows" fxLayout="row" fxLayoutAlign="center center">
      <picture fxLayout="row" fxLayoutAlign="center center">
        <source srcset="/assets/images/welcome/oferta-3-400.webp" media="(max-width: 599px)">
        <source srcset="/assets/images/welcome/oferta-3-800.webp" media="(min-width: 600px)">
        <img
            src="/assets/images/welcome/oferta-3-400.webp"
            height="195"
            width="400"
            alt="oferta-komis-gastronomia">
      </picture>
      <div class="text-box" fxLayout="column" fxLayoutAlign="center start" fxLayoutGap="5px">
        <div class="line"></div>
        <h3>Sprzedaż</h3>
        <p>Sprzęt gastronomiczny, biurowy</p>
      </div>
    </a>
  </div>
</section>

<section class="section__main main-text-content section-main-desc" fxLayout="column" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
  <div fxLayout="column" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
    <h1>Komis gastronomiczny</h1>
    <h2>Skup, sprzedaż sprzętu gastronomicznego oraz innego sprzętu w tym biurowego.</h2>
  </div>
  <div class="line"></div>
  <p>Nasza oferta obejmuje kompleksowe wyposażenie obiektów gastronomicznych, przetwórstw oraz sklepów spożywczych w zarówno nowe, jak i używane maszyny. Świadczymy usługi projektowania oraz realizujemy najtrudniejsze zagadnienia w dziedzinie przetwarzania stali. Wieloletnie doświadczenie pozwoliło nam na wypracowanie technologii i standardów, które pozwalają spełnić oczekiwania każdego klienta.</p>
  <a routerLink="o-nas" class="button">Więcej o nas</a>
</section>

<section class="section__main main-text-content section-news" fxLayout="column" fxLayoutAlign="flex-start center" fxLayoutGap="20px">
  <div fxLayout="column" fxLayoutAlign="flex-start center" fxLayoutGap="10px">
    <h1>AKTUALNOŚCI I WYDARZENIA</h1>
    <h2>BĄDŹ NA BIEŻĄCO</h2>
  </div>
  <div class="line"></div>
  <p>Sprawdź najnowsze wydarzenia, zmiany, artykuły</p>

  <div *ngIf="newsLastTwo.length; else elseTemplateNews" class="news-container" fxLayout="row wrap" fxLayoutAlign="center flex-start" fxLayout.lt-md="column" fxLayoutAlign.lt-md="start center" fxLayoutGap.lt-md="10px">
    <div *ngFor="let item of newsLastTwo" class="news-item-box" fxLayout="column" fxLayoutAlign="start start" fxLayoutGap="7px">
      <a routerLink="aktualnosci" class="title-news-box" fxLayout="row" fxLayoutAlign="space-between center">
        <h3 class="title">{{item.title}}</h3>
        <p class="date">{{item.dateString}}</p>
      </a>
      <div class="line2"></div>
      <div class="news-content-container">
        <a routerLink="aktualnosci" class="img-box">
          <img [src]="item.imgSmall" alt="obrazek-artykul" width="210" height="125">
        </a>
        <p class="content-text pre-wrap">{{item.mainText}}</p>
        <div style="clear: both;"></div>
      </div>
    </div>
  </div>
  <ng-template #elseTemplateNews>
    <h2>Wczytywanie aktualności...</h2>
  </ng-template>

  <a routerLink="aktualnosci" class="button">Więcej aktualności</a>
</section>